.headerItem {
  text-decoration: none;
  color: black;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  padding-left: 50px;
  padding-right: 50px;
  font-family: "Poppins", sans-serif;
}

.headerItem:hover {
  color: #136fbb;
}