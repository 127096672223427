@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

.App {
  text-align: center;
  font-family: 'Poppins', sans-serif;
  background-color: #f6fbfe;
}

.MuiFab-root {
  position: fixed !important;
  bottom: 30px;
  right: 30px;
  font-weight: bold !important;
  font-size: 18px !important;
}

#basket-modal {
  border-radius: 12px;
  border: 2px solid #29368c;
  padding: 0px;
}

#basket-modal > h2 {
  font-weight: 600;
  color: #29368c;
  font-size: 30px;
}

#basket-modal h3 {
  font-weight: 600;
  color: #29368c;
  font-size: 20px;
  max-width: 550px;
}

.basket-product {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
  border-top: 2px solid #29368c;
  padding-left: 26px;
  padding-right: 26px;
}

.basket-product-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 20px;
}

.basket-product-right {
  display: flex;
  align-items: center;
}

.basket-product-right > img {
  width: 156px;
}

.bold-text {
  font-weight: 600;
  color: #29368c;
}

.total-price {
  background-color: #29368c;
  color: white;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 25px;
  padding-left: 26px;
  padding-right: 26px;
  font-weight: 600;
  font-size: 20px;
}

.control-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 15px;
}

.control-buttons > button, .control-buttons > a {
  color: #29368c;
  font-weight: 600;
  background: white;
  border-radius: .1875rem;
  border: none;
  outline: none;
  font-size: 20px;
  cursor: pointer;
  transition: color .1s,background .1s,border .1s;
  width: fit-content;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12);
}

.control-buttons > button:hover, .control-buttons > a:hover {
  color: #29368c;
  background-color: grey;
}

.control-buttons > a {
  text-decoration: none;
}

.quantity-wrapper {
  display: flex;
  align-items: center;
}

.quantity-wrapper > .quantity {
  display: flex;
  height: fit-content;
  margin-right: 10px;
  margin-left: 10px;
  border-radius: 16px;
  align-items: center;
}

.quantity-wrapper > .quantity > button {
  font-size: 20px;
  outline: none;
  height: 35px;
  width: 35px;
  border-radius: 16px;
  background-color: #29368c;
  color: white;
  border: 2px solid #29368c;
  transition: color .1s,background .1s,border .1s;
  cursor: pointer;
}

.quantity-wrapper > .quantity > button:hover {
  color: #29368c;
  background-color: grey;
  border: 1px solid grey;
}

.quantity-wrapper > .quantity > p {
  margin-left: 5px;
  margin-right: 5px;
}

.basket-product-right > button {
  font-size: 20px;
  outline: none;
  height: 35px;
  width: 35px;
  border-radius: 16px;
  background-color: red;
  color: white;
  border: 2px solid red;
  transition: color .1s,background .1s,border .1s;
  cursor: pointer;
  margin-left: 26px;
}

.basket-product-right > button:hover {
  color: #29368c;
  background-color: lightcoral;
  border: 1px solid lightcoral;
}

@media only screen and (max-width: 630px) {
  .basket-product {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .basket-product-right {
    width: 100%;
    justify-content: space-around;
  }

  .control-buttons {
    flex-wrap: wrap;
  }
  
  .basket-product p {
    text-align: left;
  }

  .basket-product-left > div {
    text-align: left;
  }
}

@media only screen and (max-width: 380px) {
  .control-buttons > :nth-child(1) {
    margin-bottom: 25px;
  }

  .control-buttons > button:nth-child(2) {
    height: 50px;
  }
}
